.body {
  background-image: url("images/background.webp");
  background-color: lightgrey;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

/*Removing the underline from all of the links.*/
a, a.visited, a.hover, a.active {
  text-decoration: none;
  color: black;
}

/*Removing bullet points from lists*/
li {
  list-style: none;
}

.App-body {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav {
  font-size: calc(10px + 2vmin);
  overflow: hidden;
}